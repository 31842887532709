<template>
  <div class="d-flex">
    <template >
      <div class="card" style="width: 40%">
        <div class="card-body p-0 py-4">
            <div class="d-flex justify-content-between align-items-center px-4 pb-3">
              <h2 class="m-0 mb-4">Addresses</h2>
              
              <div class="d-flex align-items-center" style="position: relative;">
                <div class="button-text d-flex justify-content-center align-items-center">
                  <a style="" @click="handleWatch" class="btn-theme" >{{isWatching ? 'Cancel' : 'Watch'}}</a>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center px-4 pb-3">
              <vue-monaco-editor 
                v-model="targets" 
                theme="vs-dark"
                language="c"
                :options="{
                  automaticLayout: true,
                  formatOnType: true,
                  formatOnPaste: true,
                }"
                style="height: 600px"
              />
            </div>
        </div>
      </div>
      <div class="card"  style="margin-left: 20px; width: 60%">
        <div class="card-body p-4">
          <div class="d-flex justify-content-between">
            <h2>Transactions</h2>
            <div style="width: 100px; margin-top: -10px;" class="button-text d-flex justify-content-center align-items-center">
              <a class="w-100 btn-theme btn-w ms-0" @click="transactions = []">Clear</a>
            </div>
          </div>
          <div v-for="data in transactions" :key="data.transaction.hash"  class="border4 mt-5">
              <div class="d-flex border amenu align-items-center justify-content-between flex-wrap">
                <a class="btn-full" :style="'background:' + getNonceColor(data.transaction)">Nonce: {{data.transaction.nonce}}</a>
                <p class="pe-1">From: {{formatAddress(data.transaction.from)}}</p>
                <div class="border-end h100p"></div>
                <p class="pe-1">To: {{formatAddress(data.transaction.to)}}</p>
              </div>
              <div class="d-flex p-3 pb-0 flex-wrap">
                <span class="me-3">Method :</span>
                <a class="border-theme" >{{getTransactionDetails(data).method ? getTransactionDetails(data).method : getTransactionDetails(data).selector}}</a>
                <p class="m-0 ms-3">Time: {{getTime(data.transaction)}}</p>
              </div>
              <div class="code-select p-3 pt-0">
                <VueJsonPretty :path="'res'" :data="getTransactionDetails(data)" style="max-width: 800px; overflow-x: auto;"/>
              </div>
          </div>
        </div>
      </div>
    </template>
    <alert-modal 
      :title="alertModalTitle"
      :icon="alertModalIcon"
      :active="alertModalActive"
      :content="alertModalContent"
      :btnOk="alertModalBtnOk"
      :callback="alertModalCallback"
      @ok="alertModalActive=false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Utils from '@/helpers/Utils';
import Config from '@/config';
// eslint-disable-next-line no-unused-vars
import Vue from 'vue';

import Listener from '@/helpers/Listener';
import AlertModal from '../../components/AlertModal.vue';
import Transaction from '@/helpers/Transaction';
// eslint-disable-next-line no-unused-vars
const baseUrl = Config.BACKEND_URL;

export default {
  name: "List",
  props: [],
  components: {
    AlertModal,
  },
  data() {
    return {
      targets: '',
      isWatching: false,
      transactions: [],
      
      colors: {},

      alertModalTitle: '',
      alertModalIcon: 'success',
      alertModalActive: false,
      alertModalContent: '',
      alertModalBtnOk: '',
      alertModalCallback: null,
    };
  },
  beforeDestroy() {
  },
  computed: {
    ...mapGetters({
    }),
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    ...mapActions({
    }),
    
    getTime(transaction) {
      const date = new Date(transaction.timeStamp);
      return date.toLocaleString();
    },
    getTransactionDetails(data) {
      return Transaction.getDetails(null, data);
    },
    getNonceColor(transaction) {
      const nonce = transaction.nonce;
      if (!this.colors[nonce]) {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        this.colors[nonce] = `rgb(${r}, ${g}, ${b});`;
      }
      return this.colors[nonce];
    },
    handleWatch() {
      if (!this.isWatching) {
        const targets = this.targets.match(/(\b0x[a-fA-F0-9]{40}\b)/g) || [];
        if (targets.length == 0) {
          return;
        }
        Listener.listen(targets, (data) => {
          console.log('new', data);
          this.transactions.push(data);
        });
      } else {
        Listener.stop();
      }
      this.isWatching = !this.isWatching;
    },
    formatAddress(address) {
      return Utils.formatAddress(address);
    },
    formatTime(date) {
      const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
      const formattedTime = formatter.format(date);

      // const formattedDate = (new Intl.DateTimeFormat('en-US', { dateStyle: 'short' })).format(date);

      return formattedTime // + ' ' + formattedDate;
    },
    timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    },
    formatNumber(num, digits) {
      num = parseFloat(num);
      if (num == 0) return num;
      if (num < 1000) return parseFloat(num.toFixed(5));
      if (!digits) digits = 3;
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
      });
      return item ? parseFloat((num / item.value).toFixed(digits)).toString().replace(rx, "$1") + item.symbol : "0";
    },
  },
};
</script>
<style scoped>
.justify-between{
  justify-content: space-between;
}

@media only screen 
    and (max-width: 767px), (min-device-width: 767px) 
    and (max-device-width: 767px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
      margin: 0 0 1rem 0;
    }
      
    /* tr:nth-child(odd) {
      background: #ccc;
    } */
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 50%;
      width: 100% !important;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 50%;
			left: 24px;
			padding-right: 10px;
			white-space: nowrap;
      transform: translate(0px, -50%);
      color: #444444;
		}

		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
    td:nth-of-type(1):before { content: "Name: "; }
		td:nth-of-type(2):before { content: "Balance: "; }
		td:nth-of-type(3):before { content: "TP/SL: "; }
		td:nth-of-type(4):before { content: "Function: "; }

    th {
      display: none;
    }
	}
</style>