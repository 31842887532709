import Config from '../config';

function uuidv4() {
  return 'Cn-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xyn]/g, function(c) {
    if (c=='n') {
        return Math.random() * 9 | 0;
    }
    var r = Math.random() * 16 | 1, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

class Socket {
  constructor() {
    this.webSocket = null;
    this.socketClosed = true;
    this.firstMessageArrived = false;
    this.callback = null;
  }

  setCallback(callback) {
    this.callback = callback;
  }

  init(config, abi) {
    if (this.close()) {
      // if not closed, then callback handler will call this func again
      // return;
    }
    this.firstMessageArrived = false;
    this.webSocket = new WebSocket("wss://api.blocknative.com/v0");
    this.socketClosed = false;

    this.webSocket.onmessage = (event) => {
      if (!this.firstMessageArrived) {
        // Communicating for protocol init
        this.firstMessageArrived = true;
        let condition = {
          "appName": 'Onboard',
          "appVersion": '1.34.1',
          "blockchain": {
            "system": "ethereum",
            "network": 'main'
          },
          "categoryCode": "configs",
          "config": {
            // "scope": router,
            // "filters": filters,
            "watchAddress": true,
            ...config
          },
          "dappId": Config.SOCKET_DAPP_ID,
          "eventCode": "put",
          "timeStamp": new Date().toISOString(),
          "version": '3.5.0',
        }
        if (abi) {
          condition['config']['abi'] = abi;
        }
        setTimeout(() => {
          this.webSocket.send(JSON.stringify(condition));
        }, 1000);
        return;
      }

      let data = JSON.parse(event.data);

      if (!data.event || !data.event.transaction) {
        return;
      }

      data = data.event;

      // if ( data.transaction.status != 'txStuck' || data.transaction.status != 'stuck') {
      //   data.transaction.status = 'pending';
      // }

      if (
        data.transaction.status != 'pending' 
        && data.transaction.status != 'confirmed'
        && data.transaction.status != 'failed'
      ) {
        return;
      }
      this.callback(data);
    }

    this.webSocket.onclose = () => {
      console.log('The connection has been closed and reconnecting');
      this.socketClosed = true;
      this.init();
    };

    this.webSocket.onerror = (err) => {
      console.log(err);
      console.log('The connection faced an error and reconnecting');
      this.init();
    }

    this.webSocket.onopen = () => {
      this.webSocket.send(JSON.stringify({
        "appName": 'Onboard',
        "appVersion": '3.5.0',
        "blockchain": {
          "system": "ethereum",
          "network": 'main'
        },
        "categoryCode": "initialize",
        "connectionId": uuidv4(),
        "dappId": Config.SOCKET_DAPP_ID,
        "eventCode": "checkDappId",
        "timeStamp": new Date().toISOString(),
        "version": '3.5.0',
      }));
    };
  }

  close() {
    if (this.webSocket) {
      this.webSocket.onclose = () => {};
      this.webSocket.close();
      this.webSocket = null;
      return true;
    }
    return false;
  }
}

class Listener {
  constructor() {
    this.contract = null;
    this.owner = null;
    this.abi = null;
    this.isListening = false;
    this.sockets = [];
  }

  stop() {
    if (this.sockets.length > 0) {
      for (let socket of this.sockets) {
        socket.close();
      }
      this.sockets = [];
    }
    this.isListening = false;
  }

  async listen(addresses, callback) {
    this.stop();
    this.sockets = [
      new Socket()
    ];
    this.isListening = true;
    
    const terms = addresses.map((address) => {
      return {
        'from': address
      }
    });
    const config = {
      "scope": addresses[0],
      "filters": [
        {
          "_join": "OR",
          "terms": terms
        }
      ],
      "watchAddress": true
    }

    this.sockets[0].setCallback(async (data) => {
      console.log(data);
      callback(data);
    });
    this.sockets[0].init(config);
  }
  
}

export default new Listener();