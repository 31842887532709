<template>
  <div class="d-flex">
    <template>
      <div class="card" style="width: 30%">
        <div class="card-body p-0 py-4">
            <div class="d-flex justify-content-between align-items-center px-4 pb-3">
              <h2 class="m-0">Contracts</h2>
              <div class="d-flex" style="position: relative;">
                <div class="button-text d-flex justify-content-center align-items-center">
                  <a style="" class="btn-theme" @click="loadFiles">Refresh</a>
                </div>
                <!-- <vs-select :placeholder="''" v-model="source">
                  <vs-option v-for="(sourceInfo, sourceKey) in sources" :key="'dex-'+sourceKey" :label="sourceKey" :value="sourceKey">
                    {{sourceKey}}
                  </vs-option>
                </vs-select> -->
                <!-- <input @keyup="onSearch" id="contract-address-search" type="text"  class="form-control" placeholder="Search..."> -->
              </div>
            </div>
            <div class="table-responsive">
              <div v-for="(file, index) in files" :key="'file'+index" class="w-100 px-5">
                <div class="d-flex justify-between align-items-center w-100 mb-1">
                  <h2 class="ml-2 pointer" @click="handleLoad(file)" style="">{{file}}</h2>
                  <div class="button-text d-flex justify-between align-items-center">
                    <a style="" class="btn-theme" @click="handleDelete(file)" >Delete</a>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="card"  style="margin-left: 20px; width: 70%">
        <div class="card-body p-0 py-4">
            <div class="d-flex justify-content-between align-items-center px-4 pb-3">
              <h2 class="m-0">{{ isEditing ? `Edit ${filename}` : 'Create' }}</h2>
              <div class="d-flex align-items-center" style="position: relative;">
                <template v-if="!isEditing">
                  Filename: <input :disabled="isEditing" v-model="filename" style="margin-left: 10px;" />
                  <div class="button-text d-flex justify-content-center align-items-center">
                    <a style="" class="btn-theme" @click="handleCreate">Create</a>
                  </div>
                </template>
                <template v-else>
                  <div class="button-text d-flex justify-content-center align-items-center">
                    <a style="" @click="handleUpdate" class="btn-theme" >{{isUpdating ? '...' : 'Save'}}</a>
                  </div>
                  <div class="button-text d-flex justify-content-center align-items-center">
                    <a style="" class="btn-theme" @click="handleClose">Close</a>
                  </div>
                </template>
              </div>
            </div>
            <div class="p-4">
              <!-- <textarea v-model="filecontent" class="w-100" style="height: 600px"></textarea> -->
              <vue-monaco-editor 
                v-model="filecontent" 
                theme="vs-dark"
                language="c"
                :options="{
                  automaticLayout: true,
                  formatOnType: true,
                  formatOnPaste: true,
                }"
                style="height: 600px"
              />
            </div>
            <div v-if="testResult.includes('actual')" class="p-4 pt-0 pb-0" style="font-weight: bold; color: red;">
              Test error, please check the code
            </div>
            <div class="p-4" v-if="isEditing" style="white-space: break-spaces;position: relative;">
              <div class="button-text d-flex justify-content-center align-items-center" style="position:absolute; right: 30px; top: 35px; z-index: 999;">
                <a :style="testResult.includes('actual') ? 'background:red':''" @click="handleTest" class="btn-theme" >{{isTesting ? '...' : (testResult.includes('actual') ? 'Retry' : 'Test')}}</a>
              </div>
              <!-- <textarea v-model="config" class="w-100" style="height: 400px"></textarea> -->
              <vue-monaco-editor 
                v-model="config" 
                theme="vs-dark"
                language="json"
                :options="{
                  automaticLayout: true,
                  formatOnType: true,
                  formatOnPaste: true,
                }"
                style="height: 300px"
              />
              {{testResult}}
            </div>
        </div>
      </div>
    </template>
    <alert-modal 
      :title="alertModalTitle"
      :icon="alertModalIcon"
      :active="alertModalActive"
      :content="alertModalContent"
      :btnOk="alertModalBtnOk"
      :callback="alertModalCallback"
      @ok="alertModalActive=false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Utils from '@/helpers/Utils';
import Config from '@/config';
// eslint-disable-next-line no-unused-vars
import Vue from 'vue';

import AlertModal from '../../components/AlertModal.vue';
// eslint-disable-next-line no-unused-vars
const baseUrl = Config.BACKEND_URL;

export default {
  name: "List",
  props: [],
  components: {
    AlertModal,
  },
  data() {
    return {
      config: '',
      testResult: '',
      isTesting: false,
      isUpdating: false,
      files: [],
      filename: '',
      filecontent: '',
      isEditing: false,

      alertModalTitle: '',
      alertModalIcon: 'success',
      alertModalActive: false,
      alertModalContent: '',
      alertModalBtnOk: '',
      alertModalCallback: null,
    };
  },
  beforeDestroy() {
  },
  computed: {
    ...mapGetters({
    }),
    hasTestError() {
      if (this.testResult.includes('actual')) {
        return true;
      }
      if (this.testResult.includes('Error')) {
        return true;
      }
      if (this.testResult.includes('error')) {
        return true;
      }
      return false;
    }
  },
  watch: {
    // async source() {
    //   await this.refreshAll();
    // }
  },
  async mounted() {
    this.reload();
  },
  methods: {
    ...mapActions({
    }),
    async reload() {
      this.loadFiles();
      this.loadConfig();
    },
    async handleTest() {
      await this.updateConfig();
      await this.handleUpdate();
      this.testResult = '';
      this.isTesting = true;
      // call create api with post with json parameters
      fetch(baseUrl + 'test', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: this.filename,
          content: this.filecontent
        })
      })
      // .then(async response => {
      //   if (!response.ok) {
      //       throw new Error(await response.text());
      //   }
      //   for await (const chunk of response.body.getIterator()) {
      //       console.log('got', chunk);
      //       this.testResult += chunk + '\n';
      //   }
      // })
      .then(response => response.json())
      .then(data => {
        // this.testResult += data;
        // console.log(data.stdout);
        if (data.error) {
          this.testResult += data.error;
        }
        if (data.stdout) {
          this.testResult += data.stdout;
        }
        if (data.stderr) {
          this.testResult += data.stderr;
        }
      })
      .finally(() => {
        this.isTesting = false;
      });
    },
    async handleUpdate() {
      this.isUpdating = true;
      // call create api with post with json parameters
      fetch(baseUrl + 'update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: this.filename,
          content: this.filecontent
        })
      }).finally(() => {
        this.isUpdating = false;
      });
    },
    async updateConfig() {
      fetch(baseUrl + 'config', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content: this.config
        })
      }).finally(() => {
        this.loadConfig();
      });
    },
    async loadConfig() {
      fetch(baseUrl + 'config')
      .then(response => response.json())
      .then(data => {
        this.config = data.content;
      });
    },
    async handleLoad(file) {
      fetch(baseUrl + 'load?name=' + file)
      .then(response => response.json())
      .then(data => {
        this.isEditing = true;
        this.testResult = '';
        this.filename = file;
        this.filecontent = data.content;
      });
    },
    async handleClose() {
      this.isEditing = false;
      this.filename = '';
      this.testResult = '';
      this.filecontent = '';
    },
    async handleCreate() {
      if (this.filename == '' || this.filecontent == '') {
        this.alertModalTitle = 'Warning';
        this.alertModalIcon = 'warning';
        this.alertModalActive = true;
        this.alertModalContent = `Filename and content cannot be empty`;
        this.alertModalBtnOk = 'Ok';
      }
      
      // call create api with post with json parameters
      fetch(baseUrl + 'create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: this.filename,
          content: this.filecontent
        })
      }).then((res) => res.json())
      .then((res) => {
        this.handleLoad(res.file);
      }).finally(() => {
        this.loadFiles();
      });
    },
    async handleDelete(file) {
      fetch(baseUrl + 'delete?name=' + file)
      .then(response => response.json())
      .then(() => {
        if (this.filename == file) {
          this.isEditing = false;
          this.filename = '';
          this.testResult = '';
          this.filecontent = '';
        }
      })
      .finally(() => {
        this.loadFiles();
      });
    },
    async loadFiles() {
      fetch(baseUrl + 'list')
      .then(response => response.json())
      .then(data => {
        this.files = data;
      });
    },
    formatTime(date) {
      const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
      const formattedTime = formatter.format(date);

      // const formattedDate = (new Intl.DateTimeFormat('en-US', { dateStyle: 'short' })).format(date);

      return formattedTime // + ' ' + formattedDate;
    },
    timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    },
    formatAddress(address) {
      return Utils.formatAddress(address);
    },
    formatNumber(num, digits) {
      num = parseFloat(num);
      if (num == 0) return num;
      if (num < 1000) return parseFloat(num.toFixed(5));
      if (!digits) digits = 3;
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
      });
      return item ? parseFloat((num / item.value).toFixed(digits)).toString().replace(rx, "$1") + item.symbol : "0";
    },
  },
};
</script>
<style scoped>
.justify-between{
  justify-content: space-between;
}

@media only screen 
    and (max-width: 767px), (min-device-width: 767px) 
    and (max-device-width: 767px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
      margin: 0 0 1rem 0;
    }
      
    /* tr:nth-child(odd) {
      background: #ccc;
    } */
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 50%;
      width: 100% !important;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 50%;
			left: 24px;
			padding-right: 10px;
			white-space: nowrap;
      transform: translate(0px, -50%);
      color: #444444;
		}

		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
    td:nth-of-type(1):before { content: "Name: "; }
		td:nth-of-type(2):before { content: "Balance: "; }
		td:nth-of-type(3):before { content: "TP/SL: "; }
		td:nth-of-type(4):before { content: "Function: "; }

    th {
      display: none;
    }
	}
</style>