<template>
  <header class="py-4 bg-white">
    <div class="container d-flex justify-content-lg-between align-items-center justify-between flex-wrap">
      <div class="">
        <div class="d-flex align-items-center justify-content-lg-start justify-content-center justify-between">
          <!-- <div class="" style="margin-right: 40px;">
            <div class="logo">
              <a ><img src="img/logo.svg"/></a>
            </div>
          </div> -->
          <div class="">
            <ul class="list-unstyled d-flex align-items-center m-0 ms-lg-0 ms-3">
              <li>
                <router-link to="/">
                  <a class="text-dark" >
                    Tester
                  </a>
                </router-link>
              </li>
              <!-- <li>
                <router-link to="/copytrading">
                  <a class="text-dark" style="white-space: nowrap;" >
                    Copy Trading
                  </a>
                </router-link>
              </li> -->
              <li>
                <router-link to="/launcher">
                  <a class="text-dark" style="white-space: nowrap;" >
                    Launcher
                  </a>
                </router-link>
              </li>

              <li>
                <router-link to="/watcher">
                  <a class="text-dark" style="white-space: nowrap;" >
                    Watcher
                  </a>
                </router-link>
              </li>
              <!-- <li><a class="text-dark" >Dashboard</a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Utils from '@/helpers/Utils';
import { mapGetters } from "vuex";

export default {
  name: 'Navbar',
  props: ['isConnecting'],
  components: {
  },
  data() {
    return {
      contract: '',
    }
  },
  computed: {
    ...mapGetters(["account", "balance"]),
  },
  mounted() {
  },
  methods: {
    formatAddress(address) {
      return Utils.formatAddress(address);
    }
  }
}
</script>

<style scoped>
.mobile-mt {
  margin-top: 0px;
}

@media(max-width: 530px) {
  .mobile-mt {
    margin-top: 12px;
  }
}

.justify-between {
  justify-content: space-between;
}
</style>