import Vue from 'vue'
import VueRouter from 'vue-router'
import Tester from '@/views/Tester/index.vue'
import Launcher from '@/views/Launcher/index.vue';
import Watcher from '@/views/Watcher/index.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Tester
  },
  {
    path: '/launcher',
    name: 'Launcher',
    component: Launcher,
  },
  {
    path: '/watcher',
    name: 'Watcher',
    component: Watcher,
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('ROUTER MIDDLEWARE', store.state.account);
  // if (to.name !== 'Home' && !isAuthenticated) next({ name: 'Login' })
  // else next()
  next();
})

export default router
