<template>
  <div class="main-container" id="app">
    <template v-if="isConnected">
      <header id="home" style="margin-bottom: 30px;">
        <Navbar/>
      </header>

      <div class="container d-flex justify-content-between align-items-start flex-wrap xl-gap">
        <div class="col-12 pe-xl-4 py-4">
          <router-view/>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="card" style="width: 100%">
        <div class="card-body p-4 w-100 d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-center align-items-center w-100">
              <!-- <h2 class="m-0">Login</h2> -->
              <div class="d-flex" style="position: relative;">
                <div class="button-text d-flex justify-content-center align-items-center">
                  <a style="" class="btn-theme" @click="initWeb3">Connect Wallet</a>
                </div>
              </div>
            </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import Config from '@/config';
import Web3 from "web3";
import Navbar from '@/views/Navbar';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

/* eslint-disable no-mixed-spaces-and-tabs */
export default {
  name: 'Home',
  components: {
    Navbar
  },
  beforeDestroy() {
  },
  methods: {
    
    async initWeb3() {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: Config.INFURA_ID, // required
            rpc: {
              [Config.CHAIN_ID]: Config.RPC_URL
            },
          },
        }    
      };

      const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions // required
      });

      const provider = await web3Modal.connect();

      this.provider = provider;
      const web3 = new Web3(provider);
      
      const accounts = await web3.eth.getAccounts();

      const connectedAccount = accounts[0];

      console.log(connectedAccount);
      const whitelists = [
        '0xdc25868Db509603E1D315c8F2ABaBcA650c454Ae'.toLowerCase(),
        '0x3c7Bc57675B236F7CC93fb29F7d527E5BCf29e0D'.toLowerCase(),  
        '0xae1131e82c86339f6b98b63ff5105c647c90179a'.toLowerCase(),

        '0x1e047A6717Bfe0215405886281031A091b71C8e8'.toLowerCase(),
        '0x4F89D418eFd667A2Db649E8540CE4ad1dA93796a'.toLowerCase(), // g
      ]
      

      console.log(window.location.hostname)
      

      if (window.location.hostname == 'teletubbie.org') {
        const addies = [
        '0xdc25868Db509603E1D315c8F2ABaBcA650c454Ae'.toLowerCase(),
        '0xAfBF3a68cCe0Bc90147B17bE177BA4771fc2569B'.toLowerCase(),
        '0x65AA923F71384D373aD7521b854863Bc90d1b261'.toLowerCase(),
        '0x463A4a8a17Eed7148C2ef0C915B2E07Ec1e501F4'.toLowerCase(),
        '0xba90D4f27A3fCb945211aE1E8326567a519d26b4'.toLowerCase(),
        ]
        if (!addies.includes(connectedAccount.toLowerCase())) {
          this.alertModalTitle = 'Warning';
          this.alertModalIcon = 'warning';
          this.alertModalActive = true;
          this.alertModalContent = `Cannot use this account`;
          this.alertModalBtnOk = 'Ok';
          console.log('whitelisteda', addies, connectedAccount.toLowerCase())
          return;
        }

        console.log('whitelisteda 3', addies, connectedAccount.toLowerCase())
      } else if (!whitelists.includes(connectedAccount.toLowerCase())) {
        // this.alertModalTitle = 'Warning';
        // this.alertModalIcon = 'warning';
        // this.alertModalActive = true;
        // this.alertModalContent = `Cannot use this account`;
        // this.alertModalBtnOk = 'Ok';
        return;
      }
      this.isConnected = true;
    },
  },
  computed: {
  },
  watch: {
  },
  async mounted() {    
    window.Chart.defaults.global.elements.point.backgroundColor = 'rgba(255, 0, 0, 1)'
    window.Chart.defaults.global.elements.point.borderWidth = 5;
    window.Chart.defaults.global.elements.point.radius = 0.5;
    window.Chart.defaults.global.elements.point.hoverRadius = 5;

    window.Chart.defaults.global.aspectRatio = 6;
    window.Chart.defaults.global.responsive = true;
    window.Chart.defaults.global.maintainAspectRatio = false;

    window.Chart.defaults.global.plugins.legend = {
      display: false
    };
    window.Chart.defaults.scale.position = 'right';
    window.Chart.defaults.scale.gridLines.display = false;
    window.Chart.defaults.scale.display = false;
    window.Chart.defaults.global.legend.display = false;
    window.Chart.defaults.global.legend.labels.generateLabels = function() {
      return '';
    };
    window.Chart.defaults.global.legendCallback = function () {
      return '';
    }
    window.Chart.defaults.global.tooltips.callbacks.label = function() {
      return '';
    };
    window.Chart.defaults.global.tooltips.callbacks.title = function() {
      return '';
    };
    window.Chart.defaults.global.tooltips.backgroundColor = "rgba(255,0,0,0.8)";
  },
  data() {
    return {
      isConnected: false,
    }
  }
}
</script>


<style>
.Vue-Toastification__toast--default {
    background-color: #003cff!important;
}

.main-container {
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.main-font {
  font-family: "hunger_gamesregular";
}

.second-font {
  font-family: Montserrat;
}

.pointer {
  cursor: pointer;
}

.white {
  color: white;
}

.text-center {
  text-align: center;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1800px;
  }
}

@media(max-width: 1200px) {
  .xl-gap {
    gap: 24px;
  }
}

.Vue-Toastification__container {
  z-index: 999999999999999999999999;
}
</style>
