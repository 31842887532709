const parse = require('parse');

// eslint-disable-next-line no-unused-vars
function clone(obj) {
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

import config from '../config';
export class ParseHelper {
  constructor() {
    this._initilized = false;
    this._parse = (parse);
  }

  get client() {
    return this._parse;
  }

  instance() {
    return this._parse;
  }

  getClass(className) {
    return this._parse.Object.extend(className);
  }

  getUserClass() {
    return this._parse.User;
  }

  getAccountQuery() {
    return this.getQuery('Account');
  }

  getTargetQuery() {
    return this.getQuery('Target');
  }

  getContractQuery() {
    return this.getQuery('Contract');
  }

  getHistoryQuery() {
    return this.getQuery('History');
  }

  getQuery(className) {
    return new this._parse.Query(className);
  }

  destroy() {
    return Promise.all([
    ]);
  }

  init(isRefresh) {
    if (!this._initilized || isRefresh) {
      this._parse.initialize(config.PARSE_APP_ID, config.PARSE_JS_KEY);
      this._parse.masterKey = config.PARSE_MASTER_KEY;
      this._parse.serverURL = config.PARSE_URL;
    }

    console.log(this._parse);

    this.destroy().then(() => {
      this._initilized = true;
    })
  }

  initWithParam(id, url) {
    this._parse.initialize(id, '');
    this._parse.masterKey = '';
    this._parse.serverURL = url;

    this.destroy().then(() => {
      this._initilized = true;
    })
  }

  getLiveQueryClient() {
    return new this._parse.LiveQueryClient({
      applicationId: config.PARSE_APP_ID,
      serverURL: config.PARSE_LIVE_QUERY_URL,
      javascriptKey: config.PARSE_JS_KEY
    });
  }
  
  callCloud(func, params) {
    return this._parse.Cloud.run(func, params)
  }

  getHistories(contract, skip = 0, limit = 100) {
    const query = this.getHistoryQuery();
    query.equalTo('contract', contract);
    query.descending("createdAt");
    query.skip(skip);
    query.limit(limit);
    return query.find();
  }
}

const instance = new ParseHelper();
// instance.init();
export default instance;
export const PARSE = ParseHelper;
